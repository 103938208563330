const { stringify, parse } = JSON;

const storageService = {
  get: ({ tokenKey = "paperclipsocial-web-app" } = {}) =>
    parse(localStorage.getItem(tokenKey)),
  set: ({ value, tokenKey = "paperclipsocial-web-app" }) =>
    localStorage.setItem(tokenKey, stringify(value)),
  remove: ({ tokenKey = "paperclipsocial-web-app" } = {}) =>
    localStorage.removeItem(tokenKey),
  clear: () => localStorage.clear(),
};

export default storageService;
