import { combineReducers } from "redux";
import auth from "./../reducer/auth";
import globalLoader from "./../reducer/globalLoader";

const rootReducer = combineReducers({
	auth,
	globalLoader,
});

export default rootReducer;
