import globalLoaderType from "../../actionsType/globalLoaderType";

const initialState = {
	loader: false,
};

export default (state = { ...initialState }, action) => {
	switch (action.type) {
		case globalLoaderType.GLOBAL_LOADER: {
			return { ...state, loader: action.payload };
		}

		default: {
			return { ...state };
		}
	}
};
