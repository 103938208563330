import storageService from "../../../services/storage";
import authType from "../../actionsType/authType";

const { name, email, userId } = storageService.get({ tokenKey: "paperclip-web-app" }) || {};

const initialState = {
	name: name || "",
	email: email || "",
	userId: userId || "",
};

export default (state = { ...initialState }, action) => {
	switch (action.type) {
		case authType.SET_USER: {
			return { ...state, ...action.payload };
		}

		default: {
			return { ...state };
		}
	}
};
